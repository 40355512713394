
<template>
  <div id="app">     
    <HeaderSection/>
    <HeroSection />
    <SolutionsSection />
    <!-- <RecoverSection /> -->
    <OneClickSection />
    <FunctionalitiesSection />
    <IntegrationsSection />
    <PlansSection />
    <TextMarquee backgroundColor="$brand-neutral-white" textColor="#000" :wordsArray="['A PLATAFORMA MAIS COMPLETA DO MERCADO','MIGRE GRÁTIS HOJE']"/>
    <TestimonialsSection />
    <GrowSection />
    <FooterSection />
    <ScrollToTop />
  </div>
</template>
  
  <script>
  import HeaderSection from '@/components/Header.vue';
  import HeroSection from '@/components/Hero.vue';
  import TextMarquee from '@/components/Sliders/TextMarquee.vue';
  import SolutionsSection from '@/components/Solutions.vue';
  // import RecoverSection from '@/components/Recover.vue';
  import OneClickSection from '@/components/OneClick.vue';
  import FunctionalitiesSection from '@/components/Functionalities.vue';
  import IntegrationsSection from '@/components/Integrations.vue';
  import PlansSection from '@/components/Plans.vue';
  import TestimonialsSection from '@/components/Testimonials.vue';
  import ScrollToTop from '@/components/Sliders/ScrollToTop.vue';
  import GrowSection from '@/components/Grow.vue';
  import FooterSection from '@/components/Footer.vue';

  
  export default {
    name: 'App',
    components: {
      HeaderSection,
      HeroSection,
      TextMarquee,
      SolutionsSection,
      // RecoverSection,
      OneClickSection,
      FunctionalitiesSection,
      IntegrationsSection,
      PlansSection,
      TestimonialsSection,
      ScrollToTop,
      GrowSection,
      FooterSection,
    },
    data() {
    return {
    };
  },
  mounted() {
    // start load intercom
    window.intercomSettings = {
      app_id: 'tethda3n',
    };

    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/tethda3n'
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/tethda3n';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  }
  };
  </script>
  
<style scoped lang="scss">
  #app {
    display: flex;
    flex-direction: column;
  // min-height: 100vh;
  }
</style>  