<template>
  <section class="testimonials-section">
    <div class="title-wrapper">
      <h1 class="section-title">
        <span class="title-mobile"></span>
        O que dizem nossos clientes
      </h1>
    </div>
    <Carousel v-if="isMobile" :items-to-show="0.9">
          <Slide v-for="testimonial in testimonials" :key="testimonial.alt">
            <div class="testimonial-card">
          <div class="testimonial-content">
            <div class="testimonial-text">
              <p>{{ testimonial.text }}</p>
            </div>
            <div class="testimonial-author">
              <p>{{ testimonial.author }}</p>
            </div>
          </div>
          <div class="testimonial-avatar">
            <img :src="getImagePath(testimonial.avatar)" alt="Avatar" class="avatar-image" >
          </div>
        </div>
          </Slide>
    </Carousel>
    <div class="container"> 
      <div v-if="!isMobile" class="content">
        <div v-for="testimonial in testimonials" :key="testimonial.alt" class="testimonial-card">
          <div class="testimonial-content">
            <div class="testimonial-text">
              <p>{{ testimonial.text }}</p>
            </div>
            <div class="testimonial-author">
              <p>{{ testimonial.author }}</p>
            </div>
          </div>
          <div class="testimonial-avatar">
            <img :src="getImagePath(testimonial.avatar)" alt="Avatar" class="avatar-image" >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide,  } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
  name: 'TestimonialsSection',
  components: {
    Carousel, 
    Slide
  },
  data() {
    return {
      testimonials: [
        {
          text: "O objetivo de catálogo, e utilizando outros Checkouts, as minhas campanhas de catálogo nunca otimizavam, quando eu passei para a Adoorei, o meu catálogo otimizou bastante rápido.",
          author: "Lucas B.",
          avatar: "lucas-b.png"
        },
        {
          text: "Migrei pra adoorei afim de melhorar minha taxa de conversão e buscar um checkout diferente e tive isso e muito mais. Média de 73% de conversão no checkout.",
          author: "Augusto M.",
          avatar: "augusto-m.png"
        },
        {
          text: "Nunca me senti 100% seguro usando checkouts de terceiros e isso foi um dos maiores benefícios que vi na Adoorei: segurança e confiabilidade.",
          author: "Franco M.",
          avatar: "franco-m.png"
        },
        {
          text: "Resolvi testar a Adoorei e desde o dia do teste uso em todas as minhas lojas. checkout rápido, personalizável e mantém uma taxa de conversão acima de 70%. Sem falar nas funcionalidades extras.",
          author: "Kevin L.",
          avatar: "kevin-l.png"
        }
      ],
      isMobile: false,
    };
  },
  mounted(){
    this.isMobile = window.innerWidth <= 1200; 
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 1200;
    });
  },
  updated() {
    const carouselElement = document.querySelector('.carousel .carousel__viewport');
    if (carouselElement) {
      carouselElement.style.setProperty('overflow', 'hidden', 'important');
    }
  },
  methods: {
    getImagePath(img) {
      return require(`@/assets/images/testimonials/${img}`);
    },
  }
};
</script>

<style scoped lang="scss">
.testimonials-section {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width: 100%;
  overflow: hidden !important;
  background-color: $brand-vivid-pink;
  @media (max-width: 578px) {
    max-height: 410px;
  }
}
.section-title span {
  background-color: $color-black;
  left:0px;
}

@media (max-width: 578px) {
    .section-title{
      display: none;
    }
  }

.title-wrapper {
  margin-top:69px;
  @media (max-width: 768px) {
    span {
      left: 40%;
    }
  }
}

.container {
  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction: row;
  margin-bottom: 83px;
  max-width: 100vw;
}
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  align-items:center;
  flex-direction: row;
  gap:10px;
  transition: 0.5s ease-in-out;
  // max-width:1024px;
}
.testimonial-card {
  position: relative;
  width: 275px;
  height: 319px; //default 250
  flex-shrink: 0;
  background-color: $brand-neutral-white;
  border-radius: 5px;
  margin: 30px 10px;
  padding:15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: 0.5s ease-in-out;
  @media (max-width: $laptop) {
    width:  400px;
    height: 300px;
  }
  @media (max-width: $mobile) {
    border: 1px solid #FFF;
    box-shadow: 0px 8px 8px 0px rgba(255, 172, 172, 0.10);
    width: 310px;
    height: 280px;
    padding: 0px 20px;
  }
}

.testimonial-author {
  margin-right: 50px;
  text-align: center;
  margin-bottom: 57px;
  padding-left: 10px;
  @media (max-width: 578px) {
    display: flex;
    padding-left: 14px;
    width: 100%;
    margin-top: -28px;
  }
  p {
  color: $color-black;
  font-family: $font-family;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

}

.testimonial-content {
  flex: 1;
  display:flex;
  flex-direction: column;
  justify-content:space-between;
  text-align: flex-start;

}

.testimonial-text {
  margin: 17px 19px -10px 14px;
  height: 172px; //default 144
  min-height: 172px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;

  p {
    color: $color-black;
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    @media (max-width: $laptop) {
      font-size:18px;
    }
    @media (max-width: $mobile) {
      font-size:14px;
    }
  } 
}


.testimonial-avatar {
  position: absolute;
  bottom: -66px;
  left: 50%;
  transform: translateX(-50%);
  width: 92px;
  height: 92px;
  border-radius: 50%;
  background-color: $gray-100;
  box-shadow:$box-shadow-soft;
  margin-bottom: 15px;
  overflow:hidden;
  flex-direction: column;
  @media (max-width: $tablet) {

  }

  // border: 2px solid $gray-100;
}

.avatar-image {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

</style>