<template>
  <div class="marquee-container">
    <article>
      <div class="marquee"  @mouseover="slowedScroll" @mouseleave="startScroll">
        <div class="track">
          <div class="image-container" v-for="(img, index) in topTrackImageArr" :key="index" @mouseover="removeGrayscale" @mouseleave="resetImage">
            <img :src="img.src" :alt="img.alt" :style="imageStyle" loading="lazy" draggable="false" />
          </div>
        </div>
        <div aria-hidden="true" class="track">
          <div class="image-container" v-for="(img, index) in topTrackImageArr" :key="index" @mouseover="removeGrayscale" @mouseleave="resetImage">
            <img :src="img.src" :alt="img.alt" :style="imageStyle" loading="lazy" draggable="false" />
          </div>
        </div>
      </div>

      <div class="marquee reverse" @mouseover="slowedScroll" @mouseleave="startScroll">
        <div class="track">
          <div class="image-container" v-for="(img, index) in middleTrackImageArr" :key="index" @mouseover="removeGrayscale" @mouseleave="resetImage">
            <img :src="img.src" :alt="img.alt" :style="imageStyle" loading="lazy" draggable="false" />
          </div>
        </div>
        <div aria-hidden="true" class="track">
          <div class="image-container" v-for="(img, index) in middleTrackImageArr" :key="index" @mouseover="removeGrayscale" @mouseleave="resetImage">
            <img :src="img.src" :alt="img.alt" :style="imageStyle" loading="lazy" draggable="false" />
          </div>
        </div>
      </div>

      <div class="marquee" @mouseover="slowedScroll" @mouseleave="startScroll">
        <div class="track">
          <div class="image-container" v-for="(img, index) in bottomTrackImageArr" :key="index" @mouseover="removeGrayscale" @mouseleave="resetImage">
            <img :src="img.src" :alt="img.alt" :style="imageStyle" loading="lazy" draggable="false" />
          </div>
        </div>
        <div aria-hidden="true" class="track">
          <div class="image-container" v-for="(img, index) in bottomTrackImageArr" :key="index" @mouseover="removeGrayscale" @mouseleave="resetImage">
            <img :src="img.src" :alt="img.alt" :style="imageStyle" loading="lazy" draggable="false" />
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ImageMarquee',
  data() {
    return {
      topTrackImageArr: [
        {
          src:'https://cdn.adoorei.com/imagens/apps/abmex.png',
          alt: 'abmex'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/appmax.png',
          alt: 'appmax'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/asaas.png',
          alt: 'asaas'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/cielo.png',
          alt: 'cielo'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/efi.png',
          alt: 'efi'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/getnet.png',
          alt: 'getnet'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/istpay.png',
          alt: 'istpay'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/mercadopago.png',
          alt: 'mercadopago'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/pagarme.png',
          alt: 'pagarme'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/pagbank.png',
          alt: 'pagbank'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/active_campagnin.png',
          alt: 'bling'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/tiny.png',
          alt: 'tiny'
        }
      ],
      middleTrackImageArr: [
        {
          src:'https://cdn.adoorei.com/imagens/apps/shopify.png',
          alt: 'shopify'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/woocommerce.png',
          alt: 'woocommerce'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/whatsapp.png',
          alt: 'whatsapp'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/dsers.png',
          alt: 'dsers'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/active_campagnin.png',
          alt: 'active_campagnin'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/klavyo.png',
          alt: 'klavyo'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/reportana.png',
          alt: 'reportana'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/sak.png',
          alt: 'sak'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/unicodrop.png',
          alt: 'unicodrop'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/clearsale.png',
          alt: 'clearsale'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/melhorenvio.png',
          alt: 'melhorenvio'
        }
      ],
      bottomTrackImageArr: [
        {
          src:'https://cdn.adoorei.com/imagens/apps/googlemerchantcenter.png',
          alt: 'googlemerchantcenter'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/googleads.png',
          alt: 'googleads'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/googleanalytics.png',
          alt: 'googleanalytics'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/googletagmanager.png',
          alt: 'googletagmanager'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/kwai.png',
          alt: 'kwai'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/meta.png',
          alt: 'meta'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/mgid.png',
          alt: 'mgid'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/outbrain.png',
          alt: 'outbrain'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/pinterest.png',
          alt: 'pinterest'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/taboola.png',
          alt: 'taboola'
        },
        {
          src:'https://cdn.adoorei.com/imagens/apps/tiktok.png',
          alt: 'tiktok'
        },
      ], 
      imageStyle: {
        width: '90px',
        height: '90px',
        padding: '10px',
      },
    };
  },
  methods: {
    removeGrayscale(event) {
      const imageContainer = event.currentTarget;
      const image = imageContainer.querySelector('img');

      imageContainer.style.filter = 'none';
      image.style.transform = 'scale(1.2) 1s ease'; // Alteração: Reduzindo o valor de escala
    },

    resetImage(event) {
      const imageContainer = event.currentTarget;
      const image = imageContainer.querySelector('img');

      imageContainer.style.filter = 'grayscale(100%)';
      image.style.transform = 'none';
    },

    slowedScroll() {
      const tracks = document.querySelectorAll('.track');
      tracks.forEach((track) => {
        track.style.animation = `scroll-x ${'$duration-slowed'} linear infinite`;
      });
    },

    startScroll() {
      const tracks = document.querySelectorAll('.track');
      tracks.forEach((track) => {
        track.style.animationPlayState = 'running';
      });
    },
  },
};
</script>

<style scoped lang="scss">

.marquee-container {
  display: grid;
  align-content: start;
  overflow: hidden;
  gap: $gap;
  width: 100vw;
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: $color-black;
  background-color: $brand-neutral-white;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  overflow: hidden;
  margin-bottom: 68px;
  @media (min-width: 1920px) {
      width: 1440px;
  }
  @media (max-width: 578px) {
    margin-bottom: 57px;
  }
}

.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: $gap;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
}

.track {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: $gap;
  min-width: 100%;
  animation: scroll-x $duration linear infinite;
  margin: 12px 0;
  @media (max-width: 578px) {
    gap: 30px;
    margin: 20px 0;
  }
}

.reverse .track {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes scroll-x {
  from {
    transform: translateX($scroll-start);
  }
  to {
    transform: translateX($scroll-end);
  }
}

.image-container {
  filter: grayscale(100%);
  transition: filter 0.5s ease-in, transform 1s ease-out;
}

.image-container:hover {
  filter: none;
}

.track img {
  /* Remova o filter: grayscale(100%); */
  transition: filter 0.5s ease-out;
  transform: scale(1) 0.8s ease-out;
}
</style>
