<template>
	<a :href="link" :target=" target" class="cta-button">
	<slot></slot>
	</a>
</template>

<script>
export default {
	props: {
	link: {
		type: String,
		required: true,
	},
	target: {
		type: String,
		default:'_self'
	},
	},
};
</script>

<style scoped lang="scss">
.cta-button {
	display: flex;
    align-items: center;
    justify-content: center;
	background-color: $brand-vivid-pink;
	color: $color-white;
	padding: $spacing-medium $spacing-large;
	border: none;
	border-radius: $border-radius;
	cursor: pointer;
	color: $brand-neutral-white;
	font-family: $font-family;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	text-align: center;
	transition: background-color $transition-duration $transition-easing;
	text-transform: capitalize;
	line-height: normal;
	white-space: nowrap; 
	text-decoration: none;


	&:hover {
	background-color: $button-hover;
	}
}
</style>