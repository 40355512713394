<template>
  <section class="hero-section">
      <div class="hero-container">
        <div class="hero-content" :class="{'bg-img': !screenWidth}">
          <div class="brand-description" id="anchor">
            <h1 class="title">Somos a adoorei</h1>
            <h2 class="subtitle">A plataforma <span>completa para vender online.</span></h2>
            <div class="description">
              <p>
                A única com checkout transparente, automações, gestão financeira, mineração de produtos, rastreio e muito mais.
              </p>
              <CTAButton link="https://app.adoorei.com.br/register" id="cta-btn">
                Criar conta grátis
              </CTAButton>
            </div>
          </div>
          <div class="vertical-slider">
              <div class="mobile-popups">
                <div v-for="popup in popups" :key="popup.id" class="pop-up">
                    <div class="pop-up-logo">
                      <img src="@/assets/images/brand/logo.svg" alt="popup">
                  </div>
                  <div class="pop-up-text">
                    <h3>
                      {{ popup.title }}
                    </h3>
                    <span>
                      {{ popup.message }}
                    </span>
                </div>   
                </div>     
              </div>
            </div>
          </div>
          </div>
        <TextMarquee />
  </section>
</template>

<script>
// import HeaderSection from '@/components/Header.vue';
import CTAButton from "@/components/buttons/CTAButton";
import TextMarquee from '@/components/Sliders/TextMarquee.vue';


export default {
  name: 'HeroSection',
  components: {
    // HeaderSection,
    CTAButton,
    TextMarquee
  },
  data() {
    return {
      popups: [
          {       
            title: "É VENDA QUE VOCÊ QUER?",
            message: "Venda Aprovada - R$ 123,89"
          },
          {         
            title: "PISCOU, SORRIU, PEDIDO SAIU",
            message: "Venda Aprovada - R$ 123,89"
          },
          {
            title: "HOJE ESTÁ SEM FREIO",
            message: "Venda Aprovada - R$ 123,89"
          },
          
        ],
        position: 0,
        popupHeight: 200, // Ajuste a altura do pop-up conforme necessário
        translateYOffset: 10, // Ajuste o valor para controlar a velocidade da animação
        screenWidth: 0, //Escutando o tamanho da tela para trocar o BG
        limitWidthPhone: 768 //limite para comparar se é celular e tablet
    }
  },
  mounted() {
    this.screenWidth = window.innerWidth <= this.limitWidthPhone
    this.movePopups();
  },
  methods: {
    isMobileView() {
        return window.innerWidth <= 1024;
      },
      movePopups() {
      setInterval(() => {
        this.position -= this.translateYOffset;
        if (this.position <= -this.popupHeight * this.popups.length) {
          this.position = 0;
        }
      }, 1000); // Intervalo para ajustar a velocidade da animação
    },
  }
};
</script>

<style scoped lang="scss">

.hero-section {
  display:flex;
  flex-direction: column;
  justify-content: end;
  width: 100vw;
  background: $hero-gradient-color;
  background-color: $color-black;
  @media (min-height: $desktop) {
    height:100%; 
  }

  @media (max-height:900px) {
    height: 100%;
  }
  
      .hero-container {
        flex-direction: column;
        justify-content: space-between;
        max-width:100vw;
        align-content: center;  

          .hero-content {
            display:flex;
            align-items: center;
            justify-content: start;
            margin-top:53px;
            margin-left:0px;  //103px
            flex-wrap: no-wrap;
            height: calc(100vh - 150px);
            @media (min-width: $desktop) {
              justify-content: center; //default space-evenly
            }

            @media (max-width:$tablet) {
              justify-content:center;
              margin-left: 0;
              margin-top:53px !important;
            }

            @media (max-width: 578px) {
              margin-top: 1em !important;
              height: calc(100vh - 100px);
            }

            @media (max-width:$mobile) {
              width:100%;
              margin-left:0px;
              // padding-left:5px;
            }

            @media (max-width: 578px) {
              margin-top:0px;              
            } 
            // @media (max-width:320px) {
            //   padding-bottom: 40px;
            // }
                  .brand-description {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content:center;
                    max-width: 597px;
                    padding: 6px;
                    margin-bottom: 103px; //df 153
                    transition: ease-in-out 0.5s;
                    // @media (min-width:$desktop) {
                    //   margin-right: 680px;
                    // }
                    @media (max-width:$laptop) {
                      width:80%;
                    }
                    @media (min-height: $laptop) {
                      margin-top:67px;
                      transition: ease-in-out 0.5s;
                    }
                    @media (max-height: 900px) {
                      margin-top: 103px;
                      margin-bottom: 50px;
                      transition: ease-in-out 0.5s;
                    }
                    @media (max-width:$tablet) {
                      padding: 0 20px;
                      text-align: center;
                      // margin-bottom: auto;
                    }
                    @media (max-height:900px)  {
                      transform: scale(0.8);
                    }
                    @media (max-height: 768px) {
                      margin-top: 40px;
                    }
                    @media (max-width: 576px){
                      margin-top: 0px;
                      margin-bottom: 0px;
                      transform: none;
                      width:90%;
                    }

                      .title {
                        color: $brand-neutral-white;
                        font-family: $font-family;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-bottom: -15px;
                        text-align: start;
                      }

                      @media (max-width: 578px) {
                        .title {
                          color: $brand-neutral-white;
                          font-family: $font-family;
                          font-size: 20px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          text-align: start;
                        }
                      }
                      
                      @media (max-height: 667px) {
                        margin-top: 20px;
                      }

                      .subtitle {
                        color: $brand-neutral-white;
                        font-family: $font-family;
                        font-size: 55px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        @media (max-width:$tablet) {
                          font-size: 35px;
                          text-align: start;
                        }
                        @media (max-width: 578px) {
                          font-size: 40px;
                          margin-top: 1.1em;
                        }
                        @media (max-height: 667px) {
                          font-size: 22px;
                        }

                          span {
                            background-image: $vivid-pink-markup;
                            background-position: 0% 100%;
                            background-repeat: no-repeat;
                            background-size: 100% 6px;
                          }
                      }

                    .description {
                      display:flex;
                      flex-direction: column;;
                      color: rgb(153, 153, 153, 0.6);
                      max-width: 516px;
                      font-family: $font-family;
                      font-size: 20px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      margin-top: -18px;
                      text-align: start;

                      @media (max-width:$tablet) {
                        color: #999;
                        font-size: 18px;
                        max-width: 100%;
                      }
                    }

                    p {
                      font-weight: lighter;
                      opacity: 0.80;
                    }

                    #cta-btn {
                      margin-top:47px;
                      display:flex;
                      justify-self: center;
                      align-self:center;
                      color: #FFF;
                      font-family: $font-family;
                      font-size: 20px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: normal;
                      height: 48px;
                      flex-shrink: 0;

                      @media (max-width:$tablet) {
                        width: 100%;
                      }

                      @media (max-width: 578px) {
                        width: 86%;
                      }
                    }
                  }

                  @media (min-width: 1440px) {
                    margin-left: -124px;
                  }
                  .vertical-slider {
                    align-content: center;
                    overflow: hidden !important;
                    
                    font-size: 1rem;
                    line-height: 1.5;
                    background-color: transparent;
                    overflow: hidden;
            
                    max-height: 640px; //default 775
                    height:640px; //default 640
                    transition:0.5s ease-in-out;

                  
                    @media (max-height:900px)  {
                      transform: scale(0.8);
                      transition:0.5s ease-in-out;

                    }

                  }
                  .mobile-popups {
                    position: relative;
                    left: -57px;
                    top: 48px;
                    flex-direction: column;
                    align-items: start;
                    background-color: transparent;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 129px; //default 99
                    width: 700px;
                    height: 530px; //default 640
                    max-height: 640px; //default 775
                    overflow: hidden;
                    transition:0.5s ease-in-out;
                    @media (max-width:$laptop) {
                      transition:0.5s ease-in-out;
                      display:none;
                    }
                    @media (max-height:900px)  {
                      transition:0.5s ease-in-out;
                      top: 90px;
                    }

                    @media (min-height:1440px) {
                      transition:0.5s ease-in-out;
                      height: 520px;
                    }

                    @media (min-width: $desktop) {
                      left: 60px;
                      gap: 144px;
                      top: 40px;
                    }
                    @media(min-width: 1920px) {
                      left: 114px;
                    }

                    
                  // border: 2px solid yellow;

                  // animation: scroll-y $duration linear infinite ;    
                  // @media (max-width: $mobile) {
                  //   display:hidden;
                  //   width:0px;
                  // }

                .pop-up {
                  overflow:hidden;
                  position:relative;
                  left:-125px;
                  display:flex;
                  justify-content: start;
                  user-select: none;
                  pointer-events: none;
                  flex-direction: row;
                  align-items:center;
                  width: 306px;
                  height: 68px;
                  flex-shrink: 0;
                  border-radius: 10px;
                  padding: 4px 11px;
                  border-radius: 10px;
                  backdrop-filter: blur(10px);
                  background: rgba(255, 255, 255, 0.10);
                  // animation: scroll-y 8s linear infinite;    
                  // border: 2px solid green;
                
                  &:nth-child(even) {
                    align-self: end;           
                  }

                    .pop-up-logo {
                      display:flex;
                      justify-content:center;
                      align-items:center;
                      border-radius: 10px;
                      width: 58px;
                      height: 58px;
                      background:rgb(255, 255, 255, 0.1);

                        img {
                          height: 55.191px;
                          width:57.25px;
                        }
                    }

                    .pop-up-text {
                      margin-left: 16px;
                      margin-bottom: 16px;
                          h3 {
                            color: rgba(255, 255, 255, 0.50);
                            font-family: $font-family;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom:4px;
                          }

                          span {
                            color: rgba(255, 255, 255, 0.50);
                            font-family: $font-family;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                          }
                    }
                }
              }
            }
          }
}

@keyframes scroll-y {
  to {
      transform: translate3d(0,-660px, 0);
    }

    from {
      transform: translate3d(0,0, 0);
    }
  }



.bg-img {
  position: relative;
  z-index: 1;
  image-fit:contain;
}

.bg-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/images/mocks/hero/hero-model.png');
  background-position: bottom right; 
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width:$tablet) {
    background-size: cover;
    opacity: 0.3;
  }
  z-index: -1;
}
</style>