<template>
  <footer class="footer">
    <div class="container">
        <div class="column">
          <div class="brand">
            <img src="@/assets/images/brand/logo.svg" alt="Logo"  width="52" height="52" draggable="false" loading="lazy" />
            <span class="brand-name">adoorei</span>
          </div>
          <p class="slogan">Feito para quem adoora vender mais</p>
          <div class="social-media">
            <h4 class="column-title">Siga-nos nas redes sociais</h4>
  
            <a href="https://www.facebook.com/profile.php?id=100093082903644" target="_blank" class="social-link"><img src="@/assets/images/social-links/facebook.svg" alt="Facebook" width="20" height="20" /></a>
            <a href="https://www.instagram.com/checkoutinteligente/" target="_blank" class="social-link"><img src="@/assets/images/social-links/instagram.svg" alt="Instagram"  width="20" height="20" /></a>
            <a href="https://www.linkedin.com/company/adoorei/" target="_blank" class="social-link"><img src="@/assets/images/social-links/linkedin.svg" alt="LinkedIn"  width="20" height="20" /></a>
            <a href="https://www.youtube.com/channel/UCwBhjVvbNEdZQj9QIwZnKlQ" target="_blank" class="social-link"><img src="@/assets/images/social-links/youtube.svg" alt="YouTube"   width="20" height="20"/></a>
          </div>
        </div>
        <div class="column">
          <h4 class="column-title">Institucional</h4>
          <ul class="column-links">
            <li><a href="#" target="_blank">Sobre a Adoorei</a></li>
            <li><a href="https://www.linkedin.com/jobs/search/?currentJobId=3681038236&keywords=adoorei" target="_blank">Trabalhe Conosco</a></li>
            <li><a href="https://ajuda.adoorei.com/pt-BR/articles/6695336-termo-de-uso"  target="_blank">Política de Privacidade</a></li>
            <li><a href="https://ajuda.adoorei.com/pt-BR/articles/6695336-termo-de-uso"  target="_blank">Termos e Condições</a></li>
          </ul>
        </div>
        <div class="column">
          <h4 class="column-title">Atendimento</h4>
          <ul class="column-links">
            <li><a aria-label="Central de ajuda" href="https://ajuda.adoorei.com/"  target="_blank">Central de Ajuda</a></li>
            <li><a aria-label="Denunciar fraude" href="#"  target="_blank">Denunciar Fraude</a></li>
            <li><a aria-label="Atualizações" href="#"  target="_blank">Atualizações</a></li>
          </ul>
        </div>
        <div class="column">
          <h4 class="column-title">Baixe nosso app</h4>
          <div class="app-buttons">
            <a href="https://play.google.com/store/apps/details?id=com.adoorei.lojista" target="_blank" class="app-button android" aria-label="Baixe nosso aplicativo no Android">
              <img src="@/assets/images/stores/googleplay.svg" alt="Google Play" draggable="false"  width="36" height="36" />
              <span>Google Play</span>
            </a>
            <a href="https://apps.apple.com/br/app/adoorei-checkout-transparente/id6444135489" target="_blank" class="app-button ios" aria-label="Baixe nosso aplicativo no iOS">
              <img src="@/assets/images/stores/appstore.svg" alt="App Store" draggable="false"  width="36" height="36" />
              <span>App Store</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
    <div class="footer-bottom">
      <p class="rights">
        <!-- © {{ currentYear }} Adoorei Tecnologia LTDA. Feito com muito &#x2764;&#xFE0F; no Brasil &#x1F1E7;&#x1F1F7;.
         -->
         2021 - {{ currentYear }} © CNPJ 27.306.642/0001-70 - Adoorei Solucoes Para Pagamentos e Cashback Ltda - Avenida Deputado Jamel Cecilio, 2929 - 15° Andar - Goiânia/GO - 74810-100
      </p>
    </div>
</template>

<script>

export default {
  name: 'FooterSection',
  components: {
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped lang="scss">
.footer {
  background-color:$brand-neutral-white;
  font-family: $font-family;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  margin-top:129px;
  
  @media (max-width: $laptop) {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
    .column {
      flex: 0 0 100%;
      margin-bottom: 40px;
    }
  }
}

.container {
  max-width:$laptop;
  justify-content:center;
  flex-wrap: nowrap;
  flex-direction:row;
  align-items:start;
  gap:55px;
  @media (max-width: 1366px) {
    flex-direction: column;
    justify-content:center;
    gap: 0px;
  }

  @media (max-width:$mobile) {
    margin-left: 20px;
    margin-right: 0;
  } 

  
}

.column {
  flex: 0 0 calc(23%  - 20px);
  margin-bottom: 40px;
}

.brand {
  margin-top: 20px;
  display: flex;
  align-items:center;

  & img {
    height: 50px;
  }
  & span {
    color: $color-black;
    font-family: $font-family;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.6px;
    margin-left:0.5rem;
  }
}

.slogan {
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.70);
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.social-media {
  margin-top: 20px;
}

.social-link {
  display: inline-block;
  margin-right: 10px;
  font-size: 24px;
  color: #777777;
}

.column-title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}

.column-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.column-links li {
  margin-bottom: 10px;
}

.column-links a {
  color: #666;
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  &:hover {
    color: $brand-vivid-pink;
  }
}

.app-buttons {
  margin-top: 20px;
  display:flex;
  flex-wrap: nowrap;
  align-items: center;
}

.app-button {
  display: flex; 
  align-items: center; 
  justify-content: center;
  margin-right: 10px;
  width: 138px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #000;
  color: #000;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
}

.app-button img {
  display: block;
  margin-right: 5px; 
}

.footer-bottom {
  margin-top:66px;
  text-align: center;
  max-width: 1300px;
  padding:48px;
  @media (max-width:$tablet) {
    padding:24px;
  }

  p {
    color: rgba(0, 0, 0, 0.70);
    font-family: $font-family;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      color:$brand-vivid-pink;
      font-size:25px;
    }
  }
}

.rights {
  font-family: Sora;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #777777;
  text-align: left;

}
</style>
