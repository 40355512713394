<template>
  <section class="plans-section">
    <!-- Hero content -->
    <div class="container">
      <div class="plans-description">
        <h1 class="title">Temos o plano ideal para o seu negócio</h1>
        <h2 class="subtitle">Do iniciante ao avançado, nós estamos aqui para te ajudar a escalar seu negócio</h2>
      </div>   
      <Carousel v-if="isMobile" :items-to-show="1.03">
       
          <Slide v-for="plan in plans" :key="plan.title" class="plan-carousel-slide">
            <div  :class="['plan', plan.featured ? 'featured-plan' : '']">
              <h3 class="plan-title">{{ plan.title }}</h3>
              <h4 class="plan-price" v-if="plan.price !== 'Grátis'">R$ <span class="plan-price-value">{{ plan.price }}</span>/ mês</h4>
              <h4 class="plan-price" v-else><span class="plan-price-value">{{ plan.price }} </span></h4>
              <p class="plan-description">{{ plan.description }}</p>
              <hr class="spacer-horizontal">
              <p class="plan-ideal">{{ plan.ideal }}</p>
              <div v-if="plan.featured" class="featured-plan-tag">Mais Usado</div>
            </div>
          </Slide>
    </Carousel>
      <div  v-if="!isMobile" class="plans-content">
        <div v-for="plan in plans" :key="plan.title" :class="['plan', plan.featured ? 'featured-plan' : '']">
          <h3 class="plan-title">{{ plan.title }}</h3>
          <h4 class="plan-price" v-if="plan.price !== 'Grátis'">R$ <span class="plan-price-value">{{ plan.price }}</span>/ mês</h4>
          <h4 class="plan-price" v-else><span class="plan-price-value">{{ plan.price }} </span></h4>
          <p class="plan-description">{{ plan.description }}</p>
          <hr class="spacer-horizontal">
          <p class="plan-ideal">{{ plan.ideal }}</p>
          <div v-if="plan.featured" class="featured-plan-tag">Mais Usado</div>
        </div>
      </div>
      <div class="caption">
        <p>Se você fatura mais de R$150.000,00 por mês, fale com nosso time de especialistas.</p>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
  name: 'PlansSection',
  components: {
    Carousel, 
    Slide
  },
  data() {
    return {
      plans: [
        {
          title: 'Validando',
          price: 'Grátis',
          description: '+ 2,5% por pedido pago',
          ideal: 'Ideal para quem está começando',
          featured: false,
        },
        {
          title: 'Acertei',
          price: '147',
          description: '+ 2,0% por pedido pago',
          ideal: 'Ideal para quem fatura mais de R$30k por mês',
          featured: true,
        },
        {
          title: 'Escala',
          price: '497',
          description: '+ 1,5% por pedido pago',
          ideal: 'Ideal para quem fatura mais de R$60k por mês',
          featured: false,
        },
      ],
      isMobile: false,
    };
  },
  mounted(){
    this.isMobile = window.innerWidth <= 1200; 
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 1200;
    });
  },
  
};
</script>

<style scoped lang="scss">
  

.plans-section {
  display:flex;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
  width: 100%;
  background-color: $color-black;
  background-size: 150% 200%;
  transition: background-position 0.5s;
}

.container {
  display: flex;
  flex-direction: column;;
  justify-content: center;
  align-items: center;
  margin-top: 52px;
  margin-bottom: 81px;
  @media (max-width:1024px) {
    padding:103px;
  }
  @media (max-width:768px) {
    padding:20px;
  }
  @media (max-width: 578px) {
    padding: 0px 20px;
    margin-top: 25px;
    margin-bottom: 32px;
  }
}

.plans-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
  flex-wrap: wrap;
}

.plans-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: $spacing-medium;
}

.title {
  color: $brand-neutral-white;
  font-family: $font-family;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 22px;
  text-align: center;


  span {
    background-image: $vivid-pink-markup;
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 6px;
  }
}

.subtitle {
  color:  $brand-neutral-white;
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -18px;
  text-align: center;
}

.plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 10px;
  padding: 32px 12px;
  border: 1px solid $brand-neutral-white;
  border-radius: 5px;
  background-color: transparent;
  width:  295px;
  height: 277px;
  transition: 0.5s ease-in-out;
  @media (max-width: $laptop) {
    width: 295px;
    height: 340px;
  }
  @media (max-width: $mobile) {
    width: 295px;
    height: 277px;
    padding: 0px 0px;
    margin: 0px 0px;
  }
}

.featured-plan {
  position:relative;
  border-color: $brand-vivid-pink;
  background-color: $brand-neutral-white;
  transition: 0.5s ease-in-out;
  // width: calc(100% - 314px);
  @media (max-width: $laptop) {
    width: 295px;
    height: 359px;
  }
  @media (max-width: $laptop) {
    width: 295px;
    height: 340px;
  }
  @media (max-width: $mobile) {
    width: 280px;
    height: 277px

  }
  h3 {
    color: $color-black;
  }
  h4 {
    color: $brand-vivid-pink;
  }
  p.plan-description,
  p.plan-ideal {
    color: $color-black;
  }
}

.featured-plan-tag {
    position: absolute;
    display:flex;
    align-items:center;
    justify-content:center;
    width: 139px;
    height: 33px;
    top: -38px;
    background-color: $brand-lime-green;
    color: $brand-neutral-white;
    text-transform: uppercase;
    margin: 15px 8px;
    color: $brand-neutral-white;
    text-align: center;
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px 15px;
    border-radius: 0 0 0 4px;
    z-index: 1;
    border-radius:5px; 
    transition: 0.5s ease-in-out;
}

.plan-title {
  color: $brand-neutral-white;
  text-align: center;
  font-family: $font-family;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  // margin-top: 37px;
  margin-bottom:0;

}

.plan-price {
  color: $brand-vivid-pink;
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  // margin-top: 37px;
  margin-bottom:0;


}

.plan-price-value {
  text-align: center;
  font-family: $font-family;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* .featured-price {
  color: $brand-neutral-white;  Altera a cor do preço no plano featured para branco 
}
 */
.plan-description {
  color: $brand-neutral-white;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.plan-ideal {
  color:  $brand-neutral-white;
  text-align: center;
  font-family: $font-family;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.caption {
  display:flex;
  justify-content:center;
  color: $brand-neutral-white;
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  // margin-top: 41px;
  @media (max-width: 578px) {
    font-size: 18px;
  }
}


.spacer-horizontal {
  width:100%;
  border-bottom: 1px solid  $brand-neutral-white;
}
@media (max-width:768px) {
  .plans-content {
    flex-direction:column;
}
}

 @media (max-width: 576px) {
  .plans-description {
    gap: $spacing-medium;
    justify-content:center;
  }
  .container {
    flex-direction: column;
    align-items: center;
  }

  .hero-content,
  .hero-image {
    flex-basis: 100%;
    height: auto;
  }

  .brand-description {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    text-align: center;
    margin-bottom: 40px;
    margin-right: 0; /* Remove a margem à direita na versão mobile */
  }
  
  .title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 18px;
    text-align: start;
  }

  .description {
    font-size: 18px;
    margin-top: 0;
  }
}

</style>
