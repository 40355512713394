	<template>
		<a :href="link" class="cta-button">
		<slot></slot>
		</a>
	</template>
	
	<script>
	export default {
		props: {
		link: {
			type: String,
			required: true,
		},
		},
	};
	</script>
	
	<style scoped lang="scss">
	.cta-button {
		width: 100%;
		text-decoration: none;
		background-color: transparent;
		color: $brand-vivid-pink;
		padding: $spacing-medium $spacing-large;
		border: none;
		border-radius: $border-radius;
		cursor: pointer;
		font-family: $font-family;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		transition: background-color $transition-duration $transition-easing;
		text-transform: capitalize;
		line-height: normal;
		white-space: nowrap;
		&:hover {
		color: $button-hover;
		}
	}
	</style>