<template>
  <section class="solutions-section">
    <div class="container">
      <div class="content">
        <div class="vertical-navigation">
            <h1 class="title">Nosso hub de soluções transforma o negócio de várias empresas.</h1>
          <div v-if="!isMobile" class="tab-container">
            <div class="tab-navigation">
              <div
                v-for="(item, index) in solutions"
                :key="index"
                :class="['tab', { active: currentOption === index, animating: isAnimating(index) }]"
                @click="setCurrentOption(index)"
                @mouseover="setCurrentOption(index)"
              >
                <div class="tab-indicator"></div>
                <span style="">{{ item.title }}</span>
              </div>
            </div>
            <div class="content-container">
              <div class="content-text">
                <h2 class="tab-title">{{ solutions[currentOption].description}}</h2>
                <p class="tab-text" v-html="solutions[currentOption].text" />
                <div 
                  class="tab-text text-function"
                >
                  Conheça todas funções 
                  <span 
                    style="font-size: 40px;"
                  >
                    →
                  </span>
                </div>
              </div>
              <!-- <div>
                <a :href="solutions[currentOption].link" class="tab-link"
                  >Conheça todas funções <span> →</span> <i class="arrow-right"></i
                ></a>
              </div> -->
            </div>
          </div>
        </div>
        <Carousel v-if="isMobile">
          <template #addons>
            <Pagination />
          </template>
          <Slide v-for="(solution,index) in solutions" :key="index">
            <div class="carousel-description">
              <h1 class="carousel-title"> {{solution.title}}</h1>
              <h2 class="tab-title">{{ solution.description  }}</h2>
              <p class="tab-text" v-html="solution.text"></p>
              <img :src="getImagePath(solution.image)" :alt="solution.title"/>
            </div>
          </Slide>
    </Carousel>
      </div>
      <div v-if="!isMobile" class="image-container">
        <!-- Image content here -->
        <img :src="getImagePath(solutions[currentOption]?.image)" :alt="solutions[currentOption].title" width="798" height="647"/>
      </div>
      </div>
  </section>
</template>
<script>
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
  name: 'IntegrationsSection',
  components: {
    Carousel,
    Slide,
    Pagination
  },

  data() {
    return {
      options: ['Opção 1', 'Opção 2', 'Opção 3'],
      currentOption: 0,
      previousOption: null,
      isMobile: false,
      solutions: [
        {
          title: 'Checkout',
          description: 'Checkout mais rápido do mercado',
          text: "Adoorei é a única plataforma de checkout do mercado com nota acima de 90 no Google Speed Test com tempo de carregamento 5x mais rápido que outros checkouts.",
          link: '#',
          image:'checkout.png',
        },
        {
          title: 'Gestão Financeira',
          description: 'Controle em tempo real dos seus resultados',
          text: "Tenha uma visão completa dos seus dados financeiros, marketing e de produtos para poder escalar sua empresa com saúde e segurança. ",
          link: '#',
          image:'overview.png',
        },
        {
          title: 'Automações',
          description: 'Automatize suas mensagens de recuperação',
          text: "Crie mensagens de e-mails, Whatsapp* e SMS ilimitadas para recuperar carrinhos abandonados, pix, boletos, visitas ou simplesmente avisar a movimentação de um pacote.",
          link: '#',
          image:'automation.png',
        },
        {
          title: 'Mineração de Produtos',
          description: 'Acompanhe seus concorrentes com poucos cliques',
          text: "Através da nossa poderosa ferramenta de espionagem você consegue ver o lucro e faturamento dos seus concorrentes em tempo real.",
          link: '#',
          image:'product-mining.png',
        },
        {
          title: 'Rastreio',
          description: 'Reduza seu suporte com um rastreio sempre atualizado',
          text: "Mantenha seu cliente atualizado sobre cada movimentação, mesmo que o produto ainda não esteja sendo rastreado diretamente pelos Correios.",
          link: '#',
          image:'tracking.png',
        },
      ]
    };
  },
  mounted(){
    this.isMobile = window.innerWidth <= 1200; 
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 1200;
    });
  },
  updated() {
    const carouselElement = document.querySelector('.carousel .carousel__viewport');
    if (carouselElement) {
      carouselElement.style.setProperty('overflow', 'hidden', 'important');
    }
  },
  methods: {
    setCurrentOption(index) {
      this.previousOption = this.currentOption;
      this.currentOption = index;
    },
    isAnimating(index) {
      return (
        (index === this.previousOption && this.previousOption !== null) ||
        (index === this.currentOption && this.previousOption !== null)
      );
    },
    getImagePath(image) {
      if (image) {
        return require(`@/assets/images/mocks/solutions/${image}`);
      }
    },
  }
};
</script>

<style scoped lang="scss">

.carousel__pagination-button--active::after {
  background-color: $brand-neutral-white !important;
}

.solutions-section {
  display:flex;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width: 100%;
  height: 100%;
  background-color: $brand-neutral-white;
}

.container {
  margin-top: 96px;
  margin-bottom: 76px;
  max-width:1024px;
  gap:0px;

  @media (min-width: 1920px) {
    margin-left: -60px;
  }
  @media (min-width: 1440px) {
    margin-left: -35px;
  }
  @media (max-width: 1024px) {
    margin-top: 30px;
  }
  @media (max-width: 578px) {
    margin-bottom: 34px;
    margin-top: 32px;
  }

}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap:0px;
}

.image-container {
  // border: 1px solid lighten($brand-chaos-black, $luminosity-increase);
  background-color: transparent;
  max-width: 500px;
  width:500px;
  height: 650px;
  display:flex;
  align-items: end;
  @media(max-width:$laptop) {
    align-items:center;
    max-width:100%;
    width:75%;
    height:100%;
  }

  img {
    height: 100%;
    object-fit: contain;
    @media (max-width:$tablet) {
      width:100%;
    }
  } 
}


.tab-navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 189px;
  height: 450px;
  justify-content:space-evenly;
  @media (max-width:576px) {
    max-width: 100%;
    padding:20px;
  }
}

.content-container {
  display:flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
    max-width: 324px;
    height: 100%;
    padding:20px;
  }
  
.content-text {
  display: grid;
  grid-template-rows: 0.5fr 1.6fr 0.45fr;
  max-height: 100%;
  height: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-content {
  flex-grow: 1;
  margin-left: 20px;
  width: 400px;
  height: 400px;
  overflow: auto;
}

.tab {
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  width: 200px;
  margin-bottom:10px;
  padding: 6px -6px; 
  border-radius: 10px;
  background-color: transparent;
  transition: background-color 0.3s;
  @media (max-width:576px) {
  width:100%;
  padding: 0 5px; 
  width:190px;
  }
  
}
.tab.active span{
  color: $brand-vivid-pink;
}

.tab span {
  color: $color-black;
  font-family: $font-family;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: relative;
  @media (max-width: 1024px) {
    align-items: center;
  }
  @media (max-width:576px) {
    display:contents;

  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -28px;
    width: 5px; 
    height: calc(100%  - 40px); 
    background-image: linear-gradient(to bottom, $brand-vivid-pink 50%, $brand-vivid-pink 50%);
    background-size: 100% 5px;
    @media (max-width:576px) {
      display:none;
    }
  }
}

.tab-indicator {
  position: absolute;
  top: 0;
  left: -20px;
  width: 8px;
  height: 100%;
  margin-right: 5px;
  background-color: $brand-vivid-pink;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s;
}

.tab.active .tab-indicator,
.tab:hover .tab-indicator {
  transform: scaleY(1);
}

.tab.animating .tab-indicator {
  transition: transform 0.5s, background-color 0.5s;
}

.tab-content {
  flex-grow: 1;
  margin-left: 20px;
}

.tab-title {
  color: $color-black;
  font-family: $font-family;
  font-size: 20px;
  font-weight: 700;
  text-align: start;
}

.tab-description {
  color: $color-black;
  font-family: $font-family;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tab-text {
  color: #000;
  font-family: $font-family;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow-y:hidden;
  white-space: pre-line;
  @media (max-width:$mobile) {
    padding:0;
    text-align: start;
    
  }

}

.tab-link {
  display:flex;
  align-items: center;
  color: $brand-vivid-pink;
  text-decoration: none;
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 8px;
  
  span {
    position: relative;
    font-size: 44px;
    right: 5px;
    bottom:2px;
  }
}

/* .arrow-right {
  margin-left: 5px;
} */


.title {
  color: $color-black;
  font-family: $font-family;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: start;
  @media (min-width: 1280px) {
    margin-left: -34px;
  }
  @media (max-width: 1024px) {
    font-size: 30px;
  }
  @media (max-width: 768px) {
    padding: 0px 24px;
  }
  @media (max-width: 578px) {
    padding: 0px 0px;
    font-size: 28px ;
    font-weight: 700;
    text-align: center;
    margin-top: 0px;
  }
}
.carousel-description {
  display:block;
  max-width: 600px; //default 600
  font-family: $font-family;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 0;

  img {
    margin-top:33px;
    height: 280px;
  }

}
.carousel-title {
  color:$brand-vivid-pink;
  font-family: $font-family;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: start;
  margin-top: 40px;

  @media (max-width: 578px) {
    margin-top: 0px;
  }
}

@media (max-width: $laptop) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .hero-content,
  .subtitle {
    font-size: 40px;
  }
  .description {
    font-size: 18px;
    margin-top: 0;
  }
}

.text-function {
  color: #FF0080;
  font-weight: 700;
  font-size: 20px;
}

@media (max-width: 578px) {
  .carousel__slide {
    height: 100% !important;
  }

  .carousel__pagination {
    margin-top: -10px;
  }
}
</style>
