<template>
  <section class="one-click-section">
    <div class="container">
      <div class="content">
        <div class="title-wrapper">
          <div class="section-title">
            <span></span>
            Compra com 1-Click
          </div>
        </div>
         <div class="subtitle-container">
          <span>FUNCIONALIDADE EXCLUSIVA</span>
         </div>
         <div class="description-paragraph">
           <p class="description-text">
            Um processo mais rápido onde os dados pessoais, de entrega e pagamentos ficam salvos para compras futuras, garantindo experiências mais rápidas e sem atritos. Com a Compra em 1-Click você reduz em 75% o tempo de compra de seu cliente, aumentando ainda mais as finalizações de compra e o retorno à sua loja.
           </p>
         </div>
         <div class="grid-container">
           <div v-for="item in items" :key="item.title" class="grid-item">
             <h3 class="grid-item-title">{{ item.title }}</h3>
             <p class="grid-item-description">{{ item.description }}</p>
           </div>
         </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OneClickSection',
  data() {
    return {
      items: [
        {
          title: 'Cliente compra em uma loja adoorei',
          description: 'Ao finalizar a compra criamos um cadastro desse cliente em nossa plataforma.'
        },
        {
          title: 'Mesmo cliente vai comprar na sua loja',
          description: 'Identificamos automaticamente esse cliente.'
        },
        {
          title: 'O cliente informa o e-mail no checkout',
          description: 'Seu cliente fará uma compra mais simples, rápida e segura. Basta inserir o código enviado por SMS.'
        },
        {
          title: 'Pronto, compra finalizada com sucesso',
          description: 'Com apenas um click seu cliente conseguiu finalizar a compra.'
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.one-click-section {
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  width: 100%;
  background-color: $color-black;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 92px;
  @media (max-width: 578px) {
    margin-bottom: 26px;
  }
}

.section-title {
  color: $brand-neutral-white;
  font-family: $font-family;
  font-size: 40px;
  margin-top: 97px;
  font-weight: bold;
  text-align: center;
  h2 {
    text-wrap: balance;
  }
  @media (max-width: 578px) {
    font-size: 28px;
    margin-top: 70px;
    margin-bottom: 11px;
  }
}

.subtitle-container {
  display: flex;
  justify-content: center;
  span {
    color: $brand-neutral-white;
    color: #FFF;
    text-align: center;
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background-color: #333;
    padding: 14px 21px;
    text-align: center;
    @media (max-width: 578px) {
      color: #FFF;
      text-align: center;
      font-family: Sora;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 14px 42px;
    }
  }
}

/* .subtitle-text {
  color: $brand-neutral-white;
  color: #FFF;
  text-align: center;
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
} */

.description-paragraph {
  // padding: calc($spacing-large*4);
  padding: calc($spacing-large*2) 0;
  @media (max-width:1024px) {
    padding:5% 5%;
  }
  @media (max-width:578px) {
    padding: 0px 21px;
    margin-top: 44px;
  }
}

.description-text {
  color: $brand-neutral-white;
  text-align: center;
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 578px) {
  .description-text {
    text-align: start;
  }
}

.grid-container {
  display: grid;
  justify-content:start;
  align-items:start;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 108px;

  @media (max-width: $laptop) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px;
  }

  @media (max-width: 578px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    grid-gap: 0px;
    width: 100%;
  }
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content:start;
  max-width:226px;
  @media (max-width:$laptop) {
    padding: 5% 10%;
    max-width: 100%;
  }
  @media (max-width: 578px) {
    display: grid;
    grid-template-rows: 1fr;
    padding: 0px 21px;
    max-width: 100%;
  }
}

.grid-item-title {
  color: #FFF;
  font-family: $font-family;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 578px) {
      font-size: 20px;
  }
}



.grid-item-description {
  color: $brand-neutral-white;
  color: #FFF;
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
  @media (max-width: 578px) {
    font-size: 18px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 100%;
    padding: 0px 103px;
  }
}

.title-wrapper {
  margin-bottom: 20px;
  
  @media (max-width: 578px) {
    margin-bottom: 0px;
  }
}
</style>
