<template>
    <div :style="marqueeContainerStyle" class="marquee-container">
      <article>
        <div class="marquee">
          <div class="track">
            <span v-for="(item, index) in textArray" :key="index" :style="textStyle">
              {{ item }}
            </span>
          </div>
          <div aria-hidden="true" class="track">
            <span v-for="(item, index) in textArray" :key="index + textArray.length" :style="textStyle">
              {{ item }}
            </span>
          </div>
        </div>
      </article>
    </div>
  </template>

<script>
export default {
  name: 'TextMarquee',
  props: {
    backgroundColor: {
      type: String,
      default: '#FF0080' // Default background color (vivid pink)
    },
    wordsArray: {
      type: Array,
      default: () => ['Checkout Transparente', 'Automação', 'Gestão Financeira'] // Default array of words
    },
    textColor: {
      type: String,
      default: '#FFF' // Default text color (white)
    }
  },
  computed: {
    marqueeContainerStyle() {
      return {
        backgroundColor: this.backgroundColor
      };
    },
    textArray() {
      let modifiedArray = [];
      for (let i = 0; i < this.wordsArray.length; i++) {
      
        modifiedArray.push(' • ', this.wordsArray[i]);
      }
      return modifiedArray;
    },

    
    textStyle() {
      return {
        color: this.textColor
      };
    }
  }
};
</script>  
  
  <style scoped lang="scss">
  .marquee-container {
    display: grid;
    align-content: center;
    overflow: hidden;
    gap: $gap;
    width: 100%;
    font-family: $font-family;
    min-height: 100px;
    font-size: 1rem;
    line-height: 1.5;
    // padding:20px 0;
    z-index:5;
    color: $color-black;
    background-color: $brand-neutral-white;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    overflow: hidden;
    @media (max-width: 578px) {
      align-content: end;
    }
  }
  
  .marquee {
    display: flex;
    overflow: hidden;
    user-select: none;
    mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 15%,
    hsl(0 0% 0% / 1) 85%,
    hsl(0 0% 0% / 0)
  );
  }

  .track {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-width: 100%;
    animation: scroll-x $duration linear infinite;
  }
  
  @media (max-width: 578px) {
    .track{
      margin-bottom: 16px;
    }
  }

  span {
    font-family: $font-family;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    padding: $spacing-medium $spacing-x-large ;
  }

  @media(max-width: 578px) {
    span{
      font-size: 32px;
    }
  }
  
  .square {
    width: 10px; /* Customize the width of the square */
    height: 10px; /* Customize the height of the square */
    background-color: $color-black; /* Customize the color of the square */
    margin:  0 $gap; /* Customize the spacing between the square and words */
  }
  
  
  .reverse .track {
    animation-direction: reverse;
    animation-delay: -3s;
  }
  
  @keyframes scroll-x {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(-800px);
    }
  }
  </style>
  