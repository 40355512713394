<template>
  <div class="container">
    <div class="title-wrapper">
    <h1 class="section-title">
      <span></span>
        Integrações
    </h1>
  </div>
<div class="container">
  <ImageMarquee />
</div>

  </div>
</template>

<script>
import ImageMarquee from '@/components/Sliders/ImageMarquee.vue';


export default {
  name: 'IntegrationsSection',
  components: {
    ImageMarquee
  },
};
</script>

<style scoped lang="scss">
.container {
  flex-direction: column;
  align-items:center;
  max-width:100vw;
  overflow:hidden;

.title-wrapper {
  margin-top: 35px;
  margin-bottom: 20px;
  @media (max-width: 578px) {
    margin-bottom: 0px;
    margin-top: 48px;
  }
}
.section-title {
  color: $color-black;
  text-align: center;
  font-family: $font-family;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
}

</style>
