<template>
  <!-- Botão Scroll to Top -->
  <button class="scroll-to-top" @click="scrollToTop" v-show="showScrollButton" id="scroll-top-button">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="chevron-icon">
      <polyline points="18 15 12 9 6 15"></polyline>
    </svg>
  </button>
</template>

<script>
export default {
name:'ScrollToTop',
data() {
  return {
    showScrollButton: false,
  };
},
mounted() {
  window.addEventListener("scroll", this.handleScroll);
  
},
beforeUnmount() {
  window.removeEventListener("scroll", this.handleScroll);
},
methods: {
  handleScroll() {
    this.showScrollButton = window.pageYOffset > 100;
  },
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
},
};
</script>

<style scoped lang="scss">
.scroll-to-top {
position: sticky;
bottom: 20px;
margin-left: 4vw;
background-color: black;
color: $brand-neutral-white;
border: none;
border-radius: 50%;
width: 40px;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
font-size: 18px;
box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
opacity: 0.7;
cursor: pointer;
transition: opacity 0.3s ease-in-out;
z-index: 99999;
@media (max-width:$tablet) {
  margin-left: 5vw;
}
@media (max-width:$mobile) {
  margin-left: 5vw;
}
}

.scroll-to-top:hover {
opacity: 1;
}

.chevron-icon {
width: 24px;
height: 24px;
}
</style>
