<template>
  <section class="functionalities-section">
    <div class="container">
      <div class="title-wrapper">
        <h1 class="section-title">
          <span></span>
          Outras Funcionalidades
        </h1>
      </div>
      <div class="grid-container">
        <div
          v-for="item in data"
          :key="item.icon"
          class="brand-image"
          @mouseover="mouseOver(item)"
          @mouseleave="mouseLeave(item)"
        >
          <img :src="getImagePath(item.icon)" :alt="item.icon" class="icon" loading="lazy" draggable="false" />
          <h3 class="title" :class="{ 'title-hover': item.hover }">
            <span>{{ item.title }}</span>
          </h3>
          <p class="description">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FunctionalitiesSection',
  data() {
    return {
      data: [
        {
          icon: 'constructor.svg',
          title: 'Construtor de Checkout',
          description: 'Deixe seu checkout com sua cara, com o editor mais completo do mercado.',
          hover: false,
        },
        {
          icon: 'recover.svg',
          title: 'Recuperação Automática',
          description: 'Recuperação de carrinhos abandonados, pix e boletos.',
          hover: false,
        },
        {
          icon: 'cashback.svg',
          title: 'Cashback',
          description: 'Aumente seu LTV oferecendo cashback aos seus clientes.',
          hover: false,
        },
        {
          icon: 'client.svg',
          title: 'Área do Cliente',
          description: 'Para seus clientes consultarem os pedidos e informações de rastreios em seu site.',
          hover: false,
        },
        {
          icon: 'upsell.svg',
          title: 'Upsell 1-Click',
          description: 'Aumente seu ticket médio oferecendo um produto após a confirmação da compra.',
          hover: false,
        },
        {
          icon: 'order-bump.svg',
          title: 'Order Bump',
          description: 'Ofereça uma oferta complementar no seu checkout para aumentar o ticket médio.',
          hover: false,
        },
        {
          icon: 'discount.svg',
          title: 'Faixa de Desconto',
          description: 'Ofereça desconto baseado no valor do pedido, quanto maior mais desconto.',
          hover: false,
        },
        {
          icon: 'promotional-kit.svg',
          title: 'Kit Promocional',
          description: 'Crie kits do mesmo produto ou de produtos diferentes para vender através de um link.',
          hover: false,
        },
        {
          icon: 'coupons.svg',
          title: 'Cupons',
          description: 'Crie ilimitados cupons de descontos para sua loja.',
          hover: false,
        },
        {
          icon: 'social-tests.svg',
          title: 'Provas Sociais',
          description: 'Aumente sua conversão com as provas sociais.',
          hover: false,
        },
        {
          icon: 'webhook.svg',
          title: 'Webhook',
          description: 'Com o webhook você pode integrar sua loja com qualquer plataforma.',
          hover: false,
        },
        {
          icon: 'domain.svg',
          title: 'Domínio Próprio',
          description: 'Utilize seu próprio domínio',
          hover: false,
        },
      ],
    };
  },
  methods: {
    getImagePath(icon) {
      return require(`@/assets/images/functionalities/${icon}`);
    },
    mouseOver(item) {
      item.hover = true;
    },
    mouseLeave(item) {
      item.hover = false;
    },
  },
};
</script>

<style scoped lang="scss">
.title-wrapper {
  margin-top:65px;
  margin-bottom: 83px ;
  background:$brand-chaos-black;
  @media (max-width: 578px) {
    margin-top: 56px;
    margin-bottom: 0px;
  }
}
.section-title {
  color: $color-white;
  text-align: center;
  font-family: $font-family;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 578px) {
    
    margin-bottom: 32px;
  }
}
.functionalities-section {
  display:flex;
  justify-content:center;
  align-items:center;
  width: 100%;
  background-color: $brand-chaos-black;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 92px;
  @media (max-width: 578px) {
    margin-bottom: 84px;
  }
  @media (min-width: 1280px) {
    max-width: 100%;
    padding: 0px 103px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 108px;
  width: 100%;
  @media (max-width: $tablet) {
    grid:40px;
    }

  @media (max-width: $laptop) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px;
    .grid-container {
      max-width: 100%;
    }
  }
  @media (max-width: 576px) {
    grid-gap: 17px;
  }

  .brand-image {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  @media (max-width:$laptop) {
    padding: 5% 5%;
  }
  @media (max-width: 578px) {
    //padding: 5% 5%;
    padding: 0 0;
    margin-left: 21px;
    margin-top: 0px;
  }
    .icon {
      width: 92px;
      height: 92px;
      flex-shrink: 0;
      @media (max-width: 578px) {
        width: 62px;
        height: 62px;
      }
    }
  }
}

@media (max-width: 578px) {
  .brand-image:nth-child(2n){
    margin-left: 0px;
    margin-right: 21px;
  }
}


.title {
  color: $brand-neutral-white;
  font-family: $font-family;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 578px) {
    font-size: 20px;
  }
}


.description {
  color: #FFF;
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 578px) {
    font-size: 18px;
  }
}
</style>
