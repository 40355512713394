<template>
  <header class="header">
    <nav class="navbar">
      <div class="container">
        <div class="brand">
          <img src="@/assets/images/brand/logo.svg" alt="Logo" draggable="false" width="52" height="52"/>
          <span>adoorei</span>
        </div>
        <div v-if="!isMobile"  class="navbar-buttons">
          <TextButton link="https://app.adoorei.com.br/register">
          Criar conta grátis
          </TextButton>
          <CTAButton id="header-cta-btn" link="https://app.adoorei.com.br/">
            Acesse sua conta 
          </CTAButton >
        </div>
        <div v-else>
          <div 
            v-if="showMenu" 
            :class="{'overlay-scroll-top': scrollIntoTop,'overlay': !scrollIntoTop }" 
            @click="toggleMenu">
          </div>
          <div class="button-hamburger " @click="toggleMenu">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24" fill="none">
                <rect width="30" height="5" fill="white"/>
                <rect y="9" width="30" height="5" fill="white"/>
                <rect y="19" width="30" height="5" fill="white"/>
            </svg>            
          </div>
          <div v-if="showMenu" class="dropdown-menu">
            <a href="https://app.adoorei.com.br/register">Criar Conta Grátis</a>
            <a href="https://app.adoorei.com.br/" :style="{color: '#FF0080'}">Acesse Sua Conta</a>
          </div>
        </div>
      </div>
      </nav>
    </header>
</template>
<script>
import TextButton from "@/components/buttons/TextButton";
import CTAButton from "@/components/buttons/CTAButton";
export default {
  name: "HeaderSection",
  data() {
    return {
      isMobile: false,
      showMenu: false,
      scrollIntoTop: true
    };
  },
  components: { CTAButton, TextButton },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
  mounted() {
    // Detectar se está na versão mobile
    this.isMobile = window.innerWidth <= 1024; 
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 1024;
    });

    window.addEventListener("scroll", () => {
      // Ver a posição de rolagem e ative/desative a opacidade com margem inicial
      if (window.scrollY > 100) {
        this.scrollIntoTop = false;
      } else {
        this.scrollIntoTop = true;
      }
    });    
  },
};
</script>

<style scoped lang="scss">

#header-cta-btn {
  color: #FFF;
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 17px 43px;
}
.header {
  position:absolute;
  top:0;
  margin-top: 58px;
  z-index: 999;
  transition: background-color 0.5s ease;
  background-color: transparent;
  @media (max-width:1024px) {
    transition: ease 0.5s;
    margin-top: 16px;
    // backdrop-filter: blur(5px);
    // background: rgba(13, 13, 13, 0.395);
  }
  
}

@media (max-height: 768px) {
  .header{
    margin-top: 20px;
  }
}
@media (max-height: 667px) {
  .header{
    margin-top: 10px;
  }
}
.container {
  justify-content:space-between;
  flex-wrap: nowrap;
  flex-direction:row;
  width:100%;
  max-width:1200px;
  // @media(min-width: 1920px) {
    
  //   max-width:1440px;
  //   margin-left:auto;
  //   margin-right: auto;
  // }

  @media (max-width: 1440px) {
    justify-content:space-between;
    margin-left:60px;
    margin-right: 60px;
    max-width:1200px;
  }

  @media (max-width: 576px) {
    justify-content:space-between;
    margin-left:20px;
    margin-right:30px;
    max-width:1200px;
  }

  // @media (max-width: 1024px) {
  //   justify-content:space-around;
  //   margin-left:auto;
  //   margin-right: auto;
  //   max-width:1024px;
  // }
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100vw;
}
  .brand {
  display: flex;
  align-items:center;

  & img {
    height: 50px;
  }
    & span {
      color: $brand-neutral-white;
      font-family: $font-family;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -1.6px;
      margin-left:0.5rem;
      @media (max-height: 667px) {
        font-size: 34px;
      }
    }
  }

.navbar-buttons{ 
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  align-items:center;

  & button {
    margin-left: 10px;
  }
}

.button-hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  span {
    font-size: 20px;
    line-height: 1;
  }
}

.dropdown-menu {
  /* Estilos para o dropdown menu */
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 150%;
  width: 90%;
  text-align: center;
  z-index: 3;
  right: 0;
  background-color: black;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 28px;
  
  white-space: nowrap;

  a {
    display: block;
    text-decoration: none;
    padding: 8px 10px;
    color: $brand-neutral-white;
    font-family: $font-family;
    font-size: 20px;
    font-weight: 400;
    padding:28px;

    &:hover {
    color: darken($brand-vivid-pink,$luminosity-increase);
    }
  }
}

.overlay-scroll-top {
  position: fixed;
  top: 17%;
  left: 0;
  width: 101%;
  height: 101%;
  background-color: rgba(0, 0, 0, 0.7); 
  z-index: 2; 
}

.overlay {
  position: fixed;
  top: 0%;
  left: 0;
  width: 101%;
  height: 101%;
  background-color: rgba(0, 0, 0, 0.7); 
  z-index: 2; 
}
</style>
