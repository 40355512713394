<template>
    <section class="grow-section">
      <div class="container">
          <div class="brand-description">
            <h1 class="title">Nós te ajudamos a crescer, migre sua loja agora.</h1>
          </div>
          <div class="grow-btn">
            <CTAButton link="https://app.adoorei.com.br/register" id="GrowCTAButton">Criar Conta Grátis</CTAButton>
          </div>
        </div>
    </section>
  </template>
  
  <script>
  import CTAButton from "@/components/buttons/CTAButton";
  export default {
    name: 'GrowSection',
    components: {
        CTAButton,
      },
    };
    </script>
  
  <style scoped lang="scss">
  /* Hero styles */
  .grow-section {
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: $color-black;
    width:100%;
    
  }
  
  
  .container {
    margin-top: 45px;
    margin-bottom: 45px;
    margin-left:103px;
    margin-right:103px;
    text-align: left;
    justify-content:space-between;
    max-width:1300px;
    width:100%;
    @media (max-width:1280px) {
      flex-direction: column;
      text-align:center;
    }
    @media (max-width: 578px) {
      text-align: start;
      margin: 50px 0px 50px 0px;
    }

    div {
      display: flex;
      flex-direction:row;
      align-items: center;
      justify-content: space-between;
    }
  }
  
  
  .brand-description {
    display: flex;
    flex-direction: column;
    justify-content:start;
    align-items: start;
    width: 100%;
    max-width: 775px;
    @media (max-width: 1024px) {
      width:100%;
      margin-right: 0px; 
    }
  
  }
  
  
  .title {
    color: $brand-neutral-white;
    font-family: $font-family;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: $tablet) {
      padding:20px;
    }
    @media (max-width: 578px) {
      margin-top: 0px;
      padding: 0px 13px;
    }
  }
  
  
  #GrowCTAButton {
    justify-self: center;
    width: 254px;
    height: 68px;
    font-size: 20px;
    @media (max-width: $tablet) {
      padding:20px;
    }
    @media (max-width: 578px) {
      width: 92vw;
      padding: 0px;
    }
  }
  
  .grow-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: $mobile) {
  
  
    .title {
      font-size: 30px;
    }
  
    .subtitle {
      font-size: 40px;
    }
  
    .description {
      font-size: 18px;
      margin-top: 0;
    }
  }
  </style>